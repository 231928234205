<template>
  <div class="p-4 bg-white rounded-lg" v-if="isDataLoaded">
    <DataTable
      ref="dataTable"
      :table_state="table_state"
      :store_names="store_names"
      :addComponent="componentForAdding"
      :editComponent="componentForEditing"
      :delete_url="delete_url"
      :urls="urls"
    >
      <template
        v-slot:rowActionsDropdown="{
          updateTable,
          item,
          pageLoader,
          permissions,
        }"
      >
        <div v-if="permissions('update')">
          <v-btn
            class="w-100 text-left"
            :disabled="!item.extra_data.detail.actions.copy"
            :class="
              !item.extra_data.detail.actions.copy ? 'text-muted' : 'text-dark'
            "
            @click="
              () => {
                actionCopy(item);
              }
            "
            text
            >Copy
          </v-btn>
        </div>
      </template>
    </DataTable>
  </div>
</template>

<script>
import DataTable from "@/own/components/DataTable.vue";
import AddItem from "@/own/components/settings/dyndoc/AddItem.vue";
import EditItem from "@/own/components/settings/dyndoc/EditItem";
import {
  EXPORT_TABLE_DATA,
  SET_TABLE_FILTER,
  SET_TABLE_PAGE,
  SET_TABLE_PERPAGE,
  SET_TABLE_SORTBY,
  UPDATE_TABLE_DATA,
  UPDATE_TABLE_STRUCTURE,
} from "@/core/services/store/dynamicDocument.module";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import ApiService from "@/core/services/api.service";
import SwalService from "@/core/services/swal.service";

export default {
  name: "DynamicDoc",
  components: { DataTable },
  data: () => ({
    componentForEditing: EditItem,
    componentForAdding: AddItem,
    delete_url: `/settings/dynamic_documents/destroy`,
    table_state: {
      UPDATE_TABLE_STRUCTURE: UPDATE_TABLE_STRUCTURE,
      UPDATE_TABLE_DATA: UPDATE_TABLE_DATA,
      SET_TABLE_SORTBY: SET_TABLE_SORTBY,
      SET_TABLE_FILTER: SET_TABLE_FILTER,
      SET_TABLE_PERPAGE: SET_TABLE_PERPAGE,
      SET_TABLE_PAGE: SET_TABLE_PAGE,
      EXPORT_TABLE_DATA: EXPORT_TABLE_DATA,
    },
    store_names: {
      getTableFilters: "getDYNDOCTableFilters",
      getTableState: "getDYNDOCTableState",
      getTableData: "getDYNDOCTableData",
      getTableHeaders: "getDYNDOCTableHeaders",
      getTableProperties: "getDYNDOCTableProperties",
      getTableExportUrl: "getDYNDOCExportUrl",
      getTablePermissions: "getDYNDOCTablePermissions",
    },
    urls: {
      delete: `/settings/dynamic_documents/destroy`,
    },
  }),
  methods: {
    async initTableContent() {
      await this.$store.dispatch(this.table_state.UPDATE_TABLE_STRUCTURE, {});
      await this.$store.dispatch(this.table_state.UPDATE_TABLE_DATA, {});
    },
    async updateTableContent() {
      await this.$store.dispatch(this.table_state.UPDATE_TABLE_DATA, {});
    },
    actionCopy(item) {
      this.$store.commit(SET_PAGE_LOADING, true);
      ApiService.post("/settings/dynamic_documents/copy", { id: item.id }).then(
        () => {
          this.$store.commit(SET_PAGE_LOADING, false);
          SwalService.successMessage({ html: SwalService.messages.created() });
          this.updateTableContent();
        }
      );
    },
  },
  computed: {
    isDataLoaded: function () {
      return this.$store.getters[this.store_names.getTableData];
    },
  },
  beforeMount() {
    this.$store.commit(SET_PAGE_LOADING, true);
    this.initTableContent();
  },
};
</script>
